<template>
  <b-container fluid class="maccabi-selection p-4 max-width-restriction" :class="`maccabi-count-${destinations.length}`">

    <b-row class="desktop-row" v-if="isCategoryLinkAgency">
      <b-col v-for="(dest, inx) in destinations" :key="inx" class="one-thumb">
        <router-link :to="`${dest.url}`">
          <b-img class="dest-image" thumbnail fluid :src="dest.images[0]" rounded alt="Image 1"></b-img>

          <p class="text title w-100 text-center p-0 m-0" v-if="dest.category !== 'vacation_pack'">
            {{ dest.data.hotelName[lang] }}
          </p>
          <p class="text sub-title w-100 text-center p-0 m-0" v-if="dest.category !== 'vacation_pack'">
            {{ dest.data.destinationName[lang] }}, {{ dest.data.destinationCountryName[lang] || '' }}
          </p>

          <ul class="description pt-4 px-2 w-100" style="left:0" v-else>
            <li class="w-100 text-center">{{ dest.data.hotelName[lang] }}</li>
            <li class="pr-5">
              <b-form-rating class="ui rating m-auto bg-transparent" variant="warning" inline
                :value="dest.data.stars" readonly locale="he"></b-form-rating>
            </li>
            <li class="pr-5 desc-item">
              <span class="icon ml-1"><i class="fa-solid fa-bell-concierge"></i></span>
              {{ dest.data.boardBasisName[lang] }}
            </li>
            <li class="pr-5 desc-item">
              <span class="icon ml-1"><i class="fa-solid fa-truck-plane"></i></span>
              {{ $t('login.including-transfers') }}
            </li>
            <!-- <li class="pr-5 desc-item" v-else>
              <span class="icon ml-1"><i class="fa-solid fa-truck-plane"></i></span>
              {{ dest.data.transfers }}
            </li> -->
            <li class="pr-5 desc-item">
              <span class="icon ml-3"><i class="fas fa-suitcase-rolling"></i></span>
              <span class="weight-number">{{ dest.data.roundTripFlight?.FlightDetail?.[0].FL_Max_Weight || 0 }}Kg</span>
            </li>
            <li class="pr-5 desc-item">
              <span class="icon ml-3"><i class="fas fa-briefcase"></i></span>
              <span class="weight-number">{{ dest.data.roundTripFlight?.FlightDetail?.[0].FL_Max_Weight_Trolley || 0 }}Kg</span>
            </li>
          </ul>
          <b-button class="order-button" size="sm" variant="primary">{{ $t('home.book-now.book-now') }}</b-button>
        </router-link>
      </b-col>
    </b-row>

    <div v-else-if="isEINAVBUAgency">
      <div v-for="[key, value] in dealsForEinavbuAgency.entries()" :key="key">
        <p class="text-center text-white dest-text">{{ value?.[0].name?.[lang] }}</p>
        <b-row>
          <b-col v-for="(hotel, inx) in value" :key="inx" class="one-thumb" :class="device==='mobile' && 'col-12'">
            <router-link :to="`${hotel.url}`">
              <b-img class="dest-image" thumbnail fluid :src="hotel.images?.[0]" alt="Image 1"></b-img>
              <div class="text top-title">
                {{ hotel.hotelName?.[lang] }}
              </div>
              <div class="text" v-if="device==='desktop'">
                <span>{{ (hotel.dest === 'BDV') ? 'מונטנגרו' : ( (hotel.dest === 'BUH') ? 'רומניה'  : `${hotel.name?.[lang]}`) }}</span>
              </div>

              <ul class="description p-0 m-0">
                <li>
                  <b-form-rating class="ui rating m-auto bg-transparent" variant="warning" inline
                    :value="hotel.data.stars" readonly locale="he"></b-form-rating>
                </li>
              </ul>

              <b-button class="order-button" size="sm" variant="primary" v-if="device==='desktop'">{{ $t('home.book-now.book-now') }}</b-button>
            </router-link>
          </b-col>
        </b-row>
      </div>
    </div>

    <b-row class="desktop-row" v-else>
      <b-col v-for="(dest, inx) in destinations" :key="inx" class="one-thumb">
        <router-link :to="`${dest.url}`">
          <b-img class="dest-image" thumbnail fluid :src="`${imageDomain}/assets/img/1mamish-login-image.jpg`" rounded alt="Image 1" v-if="is1MAMISHAgency"></b-img>
          <b-img class="dest-image" thumbnail fluid :src="dest.images[0]" rounded alt="Image 1" v-else></b-img>
          <!-- <p class="text top-title w-100 text-right p-0 mr-4" v-if="is1MAMISHAgency">
            {{ $t('login.mami-zikr-sale') }}
          </p> -->
          <p class="text top-title w-100 text-right p-0 mr-4" v-if="!is1MAMISHAgency && is1MaccabiFCLSHAVAgency">
            {{ $t('login.women-day') }}
          </p>
          <p class="text" v-if="!is1MAMISHAgency">
            <span>{{ (dest.dest === 'BDV') ? 'מונטנגרו' : ( (dest.dest === 'BUH') ? 'רומניה'  : `${dest.name[lang]}`) }}</span>
          </p>
          <b-button class="order-button" size="sm" variant="primary">{{ $t('home.book-now.book-now') }}</b-button>
        </router-link>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import { BContainer, BRow, BCol, BImg, BButton, BFormRating } from 'bootstrap-vue';
import { mapGetters, mapState } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'choose-maccabi-deal',
  mixins: [imageUrlMixin],
  props: {
    deals: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    BContainer,
    BRow,
    BCol,
    BImg,
    BButton,
    BFormRating,
  },
  computed: {
    ...mapState({
      isCategoryLinkAgency: (state) => state.agencyUsers.isCategoryLinkAgency,
      isEINAVBUAgency: (state) => state.agencyUsers.isEINAVBUAgency,
      categories: (state) => state.categories,
    }),
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      is1MAMISHAgency: 'GET_IS_1MAMISH_AGENCY',
      is1MaccabiFCLSHAVAgency: 'GET_IS_1_MACCABI_AGENCY',
    }),
    destinations() {
      const { deals, isCategoryLinkAgency, isEINAVBUAgency } = this;

      const dets = deals.map((deal) => ({
        dest: deal.destinationCode,
        images: (isCategoryLinkAgency || isEINAVBUAgency) && deal.categoryId === 'vacation_pack' ? [...deal.perspectiveUrl, ...deal.galleryUrl] : deal.destImageUrls,
        url: deal.selectedPackageUrl,
        name: deal.destinationName,
        category: deal.categoryId,
        data: deal,
        hotelId: deal.hotelId,
        hotelName: deal.hotelName,
      }));
      const uniqueDestList = dets.reduce((acc, current) => {
        const duplicate = acc.find((item) => (
        // eslint-disable-next-line no-nested-ternary
          isEINAVBUAgency ? item.hotelId === current.hotelId
            : isCategoryLinkAgency ? item.category === current.category : item.dest === current.dest));
        if (!duplicate) {
          acc.push(current);
        }
        return acc;
      }, []);
      return uniqueDestList;
    },
    dealsForEinavbuAgency() {
      const sortOrder = ['HER', 'RHO', 'PFO', 'BUS', 'BAK'];
      const { destinations } = this;
      if (destinations.length === 0) return [];

      return destinations
        .sort((a, b) => {
          const indexA = sortOrder.indexOf(a.dest);
          const indexB = sortOrder.indexOf(b.dest);

          return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
        })
        .reduce((hotelMap, dest) => {
          const destCode = dest.dest;
          hotelMap.set(destCode, [...(hotelMap.get(destCode) || []), dest]);
          return hotelMap;
        }, new Map());
    },
  },
  methods: {
    categoryName(code) {
      const { categories, lang } = this;
      return categories.find((cate) => cate.code === code)?.name[lang];
    },
  },
};
</script>

<style scoped>
  .maccabi-count-3 {
    width: 70%;
  }
  .maccabi-count-2 {
    width: 50%;
  }
  .maccabi-count-1 {
    width: 30%;
  }
  .maccabi-selection .dest-image {
    width: 100%;
    height: 256px;
  }

  .maccabi-selection .one-thumb {
    position: relative;
  }
  .maccabi-selection .dest-text {
    font-weight: 900;
    text-shadow: 1px 2px 5px black;
    color: white;
  }
  .maccabi-selection .one-thumb .text,
  .maccabi-selection .one-thumb .description {
    position: absolute;
    text-shadow: 1px 2px 5px black;
    color: white;
  }
  .maccabi-selection .one-thumb .description .desc-item {
    font-weight: 900;
  }
  .maccabi-selection .one-thumb .description {
    top: 0;
    list-style: none;
    font-size: 1rem;
  }
  .maccabi-selection.max-width-restriction .one-thumb .description {
    top: 3rem;
    left: 0;
  }
  .maccabi-selection.max-width-restriction .one-thumb .description li{
    margin-left: 2rem;
  }
  .maccabi-selection .one-thumb .description .icon {
    font-size: 1.2rem;
  }
  .maccabi-selection .one-thumb .description li * {
    border: none;
  }
  .maccabi-selection.max-width-restriction {
    max-width: 50%;
    margin-top: 1rem;
  }
  .maccabi-selection.max-width-restriction .one-thumb .text {
    font-size: 1.6rem;
  }
  .maccabi-selection.max-width-restriction .dest-text,
  .maccabi-selection .one-thumb .text {
    font-size: 3rem;
    font-weight: 800;
    bottom: 0;
    padding-right: 20px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .maccabi-selection .one-thumb .text.category-only {
    font-size: 1.5rem;
    top: 0;
    bottom: auto;
  }
  .maccabi-selection .one-thumb .text.title,
  .maccabi-selection .one-thumb .text.sub-title {
    bottom: 0;
    left: 0;
  }
  .maccabi-selection.max-width-restriction .one-thumb .text.top-title {
    top: 0.4rem;
    left: 1.8rem;
  }
  .maccabi-selection .one-thumb .text.top-title {
    top: 0;
  }
  .maccabi-selection .one-thumb .text.title {
    font-size: 1.8rem;
    height: 70%;
  }
  .maccabi-selection .one-thumb .text.sub-title {
    font-size: 1.3rem;
    height: 55%;
  }
  .maccabi-selection .one-thumb .order-button{
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 2rem;
    margin-bottom: 1rem;
  }
    .maccabi-selection.max-width-restriction .one-thumb .description li * {
      font-size: 1.2rem;
    }

  @media (max-width:479px){
    .maccabi-selection {
      width: 100%;
    }
    .maccabi-selection .one-thumb .text{
      padding-right: 10px;
      font-size: 1.5rem;
    }
    .maccabi-selection .one-thumb{
      margin-bottom: 10px;
    }
    .maccabi-selection .desktop-row{
      display: block;
    }
    .maccabi-selection .one-thumb .order-button{
      margin-bottom: 0.5rem;
    }
    .maccabi-selection.max-width-restriction {
      max-width: 100%;
      margin-top: 0rem;
    }
    .maccabi-selection.max-width-restriction .one-thumb .top-title {
      margin-right: 0.5rem;
      font-size: 1.2rem;
      max-width: fit-content;
      line-height: 1rem;
      margin-top: 0.5rem;
    }
    /* .maccabi-selection.max-width-restriction .one-thumb .text.top-title {
      font-size: 0.6rem;
      top: 0;
    } */
    .maccabi-selection.max-width-restriction .one-thumb .description {
      top: 2rem;
    }
    .maccabi-selection.max-width-restriction .one-thumb .description li{
      margin-right: 0.5rem;
    }
    /* .maccabi-selection.max-width-restriction .one-thumb .description li * {
      font-size: 0.8rem;
    } */
    .maccabi-selection.max-width-restriction .dest-text {
      font-size: 1.5rem;
    }
  }
</style>
